<template>
	<!-- 积分充值 -->
	<div>
		<a-row type="flex" :gutter="24">
			<a-col :span="24" :xxl="6" :xl="8" :lg="12" :sm="12" class="mb-24" v-for="item in integralData" :key="'card_' + item.id">
				<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: '20px 16px' }">
					<div class="body_box">
						<div class="">
							<span class="text-primary text-sm font-bold">{{ item.title }}</span>
						</div>
						<div class="flex-csb">
							<h3>{{ item.usable }}积分</h3>
							<span class="text-primary font-bold">永久有效</span>
						</div>
						<p class="text-sm text-gray-7">{{ item.desc }}</p>
						<a-button type="primary" shape="round" block style="height: 50px; font-size: 14px" @click="shopNow(item)">立即购买{{ item.price }}￥</a-button>
						<p class="text-primary font-bold text-center text-line mt-5 mb-30">{{ item.original_price }}￥</p>
						<div class="flex-csb mb-10" v-for="content in item.content" :key="'content_' + content.id">
							<div>
								<a-icon type="check-circle" theme="filled" :style="{ color: '#1890FF' }" />
								<span class="ml-5 text-dark">{{ content.text }}</span>
							</div>
							<div class="text-primary">{{ content.limit }}</div>
						</div>
					</div>
				</a-card>
			</a-col>
		</a-row>
		<PayModal :showModal="showModal" payType="integral" :prepayData="prepay" @hideModal="showModal = false"></PayModal>
	</div>
</template>

<script>
	import PayModal from "./PayModal.vue"
	export default {
		components: {
			PayModal
		},
		data() {
			return {
				integralData: [],
				prepay: {},
				showModal: false
			}
		},
		created() {
			this.getIntegralList()
		},
		methods: {
			// 立即购买
			shopNow(item) {
				this.prepay = {
					id: item.id,
					title: item.title + " " + item.usable + "积分",
					price: item.price
				}
				this.showModal = true
			},
			// 获取积分列表
			getIntegralList() {
				this.$http("recharge.integralList").then(res => {
					if (res.code === 1) {
						if (res.data.list && res.data.list.length) {
							res.data.list.map(item => {
								if (item.content) {
									let id = 0
									let arr = []
									for (let key in item.content) {
										id++
										arr.push({
											id,
											text: key,
											limit: item.content[key]
										})
									}
									item.content = arr
								}
							})
							this.integralData = res.data.list
						}
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped></style>
